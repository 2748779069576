import { explanationType, explanationTypeMap, SUPPORTED_CURRENCIES, vatData } from "@/constants";
import { InputWithAlert } from "../ui/input-with-alert";
import { SelectWithAlert } from "../ui/select-with-alert";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { vatMap } from "../admin-page-components/admin-create-components/invoice-create-components/InvoiceItem";
import { Button } from "../ui/button";

export const BankTransactionForm = (props) => {

    const {
        title,
        counterparty,
        date,
        account,
        currency,
        vat,
        grossAmount,
        transactionType,
        edit,
        onSaved,
    } = props;

    const { t } = useTranslation();
    const [titleState, setTitleState] = useState(title ? title : '');
    const [counterpartyState, setCounterpartyState] = useState(counterparty ? counterparty : '');
    const [dateState, setDateState] = useState(date ? date : '');
    const [accountState, setAccountState] = useState(account ? account : '');
    const [currencyState, setCurrencyState] = useState(currency ? currency : '');
    const [vatState, setVatState] = useState(vat ? vat : '23%');
    const [grossAmountState, setGrossAmountState] = useState(grossAmount ? grossAmount : 0);
    const [vatAmountState, setVatAmountState] = useState(0);
    const [netAmountState, setNetAmountState] = useState(0);
    const [transactionTypeState, setTransactionTypeState] = useState(transactionType ? transactionType : 'abonament');

    useEffect(() => {
        setVatAmountState((grossAmountState * vatMap[vatState]) / (100 + vatMap[vatState] ) )
    }, [vatState, grossAmountState]);

    useEffect(() => {
        setNetAmountState(grossAmountState - vatAmountState);
    }, [grossAmountState, vatAmountState]);

    return (
        <>
            {!edit && (
                <div className="w-full">
                    <div >
                        <InputWithAlert
                            label={t('reports:explanation')}
                            value={titleState}
                            onChange={(e) => setTitleState(e.target.value)}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label={t('counterparty')}
                            value={counterpartyState}
                            onChange={(e) => setCounterpartyState(e.target.value)}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label={t('reports:booking_date')}
                            value={dateState}
                            disabled={true}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label={t('invoices:payment_account')}
                            value={accountState}
                            disabled={true}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label={t('invoices:currency')}
                            value={currencyState}
                            disabled={true}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <SelectWithAlert
                            label={t('invoices:positions_type_of_vat')}
                            value={vatState}
                            options={vatData.map((option, index) => ({ value: option, label: option }))}
                            onChangeValue={(e) => setVatState(e)}
                            inputClassName={'h-8 text-xs p-[4px] bg-white mt-1'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label={t('invoices:price_brutto')}
                            value={grossAmountState}
                            onChange={(e) => setGrossAmountState(e.target.value)}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label={t('invoices:positions_vat')}
                            value={vatAmountState.toFixed(2)}
                            disabled={true}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label="Net amount"
                            value={netAmountState}
                            disabled={true}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <SelectWithAlert
                            label={t('reports:explanation_type')}
                            value={transactionTypeState}
                            options={explanationTypeMap}
                            onChangeValue={(e) => setTransactionTypeState(e)}
                            inputClassName={'h-8 text-xs p-[4px] bg-white mt-1'}
                        />
                    </div>
                </div>
            )}
            {edit && (
                <div className="w-full">
                    <div >
                        <InputWithAlert
                             label={t('reports:explanation')}
                            value={titleState}
                            onChange={(e) => setTitleState(e.target.value)}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label={t('counterparty')}
                            value={counterpartyState}
                            onChange={(e) => setCounterpartyState(e.target.value)}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <SelectWithAlert
                            label={t('invoices:currency')}
                            options={SUPPORTED_CURRENCIES}
                            onChangeValue={setCurrencyState}
                            value={currencyState}
                            inputClassName={'h-8 bg-white'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label="Gross amount"
                            value={grossAmountState}
                            onChange={(e) => setGrossAmountState(e.target.value)}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                            label={t('invoices:price_brutto')}
                            value={netAmountState.toFixed(2)}
                            disabled={true}
                            inputClassName={'h-8'}
                        />
                    </div>
                    <div className="pt-2">
                        <SelectWithAlert
                            label={t('reports:explanation_type')}
                            value={transactionTypeState}
                            options={explanationTypeMap}
                            onChangeValue={(e) => setTransactionTypeState(e)}
                            inputClassName={'h-8 text-xs p-[4px] bg-white mt-1'}
                        />
                    </div>
                    <div className="pt-2">
                        <SelectWithAlert
                               label={t('invoices:positions_type_of_vat')}
                            value={vatState}
                            options={vatData.map((option, index) => ({ value: option, label: option }))}
                            onChangeValue={(e) => setVatState(e)}
                            inputClassName={'h-8 text-xs p-[4px] bg-white mt-1'}
                        />
                    </div>
                    <div className="pt-2">
                        <InputWithAlert
                             label={t('invoices:positions_vat')}
                            value={vatAmountState}
                            disabled={true}
                            inputClassName={'h-8'}
                        />
                    </div>
                </div>
            )}
            <div className="w-full flex flex-col items-end">
                <Button className='mt-4'
                    onClick={() => {
                        onSaved(
                            titleState,
                            counterpartyState,
                            dateState,
                            accountState,
                            currencyState,
                            vatState,
                            vatAmountState,
                            grossAmountState,
                            netAmountState,
                            transactionTypeState
                        )
                    }}
                >
                    {t('button_add')}
                </Button>
            </div>
        </>
    )
}

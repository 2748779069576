import { BILL_STATUS, CONTRACT_PIT, CONTRACT_PITS, CONTRACT_TAX_DEDUCTIBLES, CONTRACT_TYPE, CONTRACT_TYPES, parseNumber, Signatories, SUPPORTED_CURRENCIES, transformFromIdValueToValueLabel } from "@/constants";
import { getBills, getContracts, getOneClient, getRateToPLN, getSpecificaiton, getUser } from "@/service/weexpertService"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Search } from "../ui/combobox-search"
import { InputWithAlert } from "../ui/input-with-alert";
import { SelectWithAlert } from "../ui/select-with-alert";
import { Switch } from "../ui/switch";
import { Button } from "../ui/button";
import { useQuery } from "react-query";
import { toast } from "../ui/use-toast";
import { DatePicker } from "../ui/date-picker";
import { format, parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { Textarea } from "../ui/textarea";

export const BillForm = ({ onSaved, initialContract = null, bill = null, className }) => {
  const [contract, setContract] = useState(initialContract);

  const { data: bills, isLoading, refetch } = useQuery({
    queryKey: ['bills_for_client', contract?.client?.id], queryFn: () => {
      if (!contract) {
        return []
      }
      return getBills(1000, 0, null, [{ 'field': 'client', value: [contract?.client.id] }])
    },
  })

  const [clientBankAccounts, setClientBankAccounts] = useState(undefined);
  const [contractType, setContractType] = useState(bill ? bill.billType : contract ? contract.contractType : undefined);
  const [taxDebuctible, setTaxDeductible] = useState(bill ? bill.taxDeductible : contract ? contract.taxDeductible : undefined);
  const [pit, setPit] = useState(bill ? bill.pit : contract ? contract.pit : undefined);
  const [grossAmount, setGrossAmount] = useState(bill ? bill.grossAmount : 0.00);
  const [emerytalneRentowe, setEmerytanleRentowe] = useState(bill ? (bill.disabilityContribution > 0 && bill.pensionContribution > 0) : false);
  const [chorobowe, setChorobowe] = useState(bill ? bill.sicknessContribution > 0 : false);
  const [zdrowotna, setZdrowotne] = useState(bill ? bill.healthInsuranceContribution > 0 : false);
  const [billSum, setBillSum] = useState(0);
  const [status, setStatus] = useState(bill ? bill.billState : 'Pending');
  const [signatory, setSignatory] = useState(bill ? bill.signatory : '');
  const [comment, setComment] = useState(bill ? bill.comment : '');
  const [bankAccount, setBankAccount] = useState(bill ? bill.bankAccount : '');
  const [conclusionDate, setConclusionDate] = useState(bill ? new Date(bill.conclusionDate) : new Date())
  const [acceptedDate, setAcceptedDate] = useState(bill ? (bill.acceptedDate ? parseISO(bill.acceptedDate) : new Date()) : new Date());
  const { t } = useTranslation();
  const [specifications, setSpecifications] = useState(contract?.specifications ?? []);
  const [specification, setSpecification] = useState(bill?.specificationItem?.specification.id ?? null);
  const [specificationStages, setSpecificationStages] = useState(bill?.specificationItem?.specification.specificationItems ?? []);
  const [specificationStage, setSpecificaitonStage] = useState(bill?.specificationItem?.id ?? null);
  const [billId, setBillId] = useState(bill?.billId ?? '')

  const [currency, setCurrency] = useState(bill?.currency ?? 'PLN')
  const [currencyRate, setCurrencyRate] = useState(bill?.currencyRate ?? '')
  const [currencyDate, setCurrencyDate] = useState(bill && bill.currencyRateDate ? new Date(bill.currencyRateDate) : undefined)

  const { data: user, isLoading: isUserLoading, isFetched: userIsFetched } = useQuery({ queryKey: 'userMe', queryFn: getUser, retry: false });
  const edit = !!bill;
  useEffect(() => {
    if (specification) {
      getSpecificaiton(specification)
        .then((res) => {
          setSpecificationStages(res.data.specificationItems);
        })
    }
  }, [specification]);

  useEffect(() => {
    refetch()
    if (contract) {
      if (contract && contract.specifications && contract.specifications.length > 0) {
        setSpecifications(contract.specifications);
        setSpecification(contract.specifications[0].id)
      }
      getOneClient(contract.client.id)
        .then(res => {
          setClientBankAccounts(res.data.bankAccounts.filter(e => e.accountType === "CLIENT"))
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            console.error("Bad Request:", error.response.data);
          } else {
            console.error("Error:", error);
          }
        });
    }
  }, [contract])

  useEffect(() => {
    if (!contract || !contract.bills) {
      return;
    }
    setBillSum((contract.bills || []).filter(e => e.billState === 'Accepted').reduce((acc, current) => acc + (+current.grossAmount), 0).toFixed(2))
  }, [grossAmount, contract])

  const errorMessage = t('empty_data_invalid');
  const totalIncome = bills?.data?.bills?.filter(e => bill ? (e.id !== bill.id && e.billState === 'Accepted') : e.billState === 'Accepted').reduce((a, b) => a + b.income, 0) ?? 0.00;
  const isPit12Avalible = totalIncome < 120000;
  useEffect(() => {
    if (currency == 'PLN') {
      setCurrencyRate(1.00)
      return;
    }
    if (currencyDate) {
      getRateToPLN(currency, format(currencyDate, "yyyy-MM-dd"))
        .then(res => {
          setCurrencyRate(res.data.midRate)
        }).catch((e) => {
          console.log(e)
        })
    } else {
      setCurrencyRate('')
    }
  }, [currency, currencyDate])

  return (
    <>
      <div className="pt-5 flex justify-end">
        <Button className="w-36 mr-2 text-blueText bg-blueLightAccent hover:bg-blueLightAccent border-[1px] border-blueBorder shadow-none" onClick={() => {
          if (contract && contract.contractType != CONTRACT_TYPE.UMOWA_ZLECENIE &&
            contract.actualGrossAmount - billSum - grossAmount < -0.01) {
            toast({
              title: "Kwota brutto nie jest prawidlowa",
              variant: 'destructive'
            })
            return
          }
          if (false === isPit12Avalible && pit !== CONTRACT_PIT.THIRTY_TWO_PERCENT && !bill) {
            toast({
              title: "Stawka PIT musi byc 32%",
              variant: 'destructive'
            })
            return
          }
          onSaved(
            contract,
            contractType,
            taxDebuctible,
            pit,
            grossAmount,
            emerytalneRentowe,
            chorobowe,
            zdrowotna,
            status,
            bankAccount,
            conclusionDate,
            acceptedDate,
            specificationStage,
            signatory,
            comment,
            billId,
            currency,
            currencyDate
          )
        }}>
          {t("button_add")}
        </Button>
      </div>
      <div className={className}>
        <div className='w-full flex flex-wrap justify-between pt-6'>
          <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12">
            <div className='w-full'>
              <Search
                fetchOption={(query) => {
                  return getContracts(100, 0, query, [])
                    .then((res) => {
                      return res.data.contracts
                        .map(contract => {
                          return {
                            label: contract.contractId + ` (${contract.client.firstName} ${contract.client.lastName})`,
                            value: contract.id,
                            contract: contract
                          }
                        })
                    })
                }}
                placeholder={t('contracts:contract')}
                label={t('contracts:contract')}
                value={contract ? contract.contractId : null}
                readOnly={initialContract}
                error={!contract}
                errorMessage={t('empty_data_invalid')}
                onChange={
                  (e) => {
                    setContract(e.contract);
                    setContractType(e.contract.contractType)
                    setPit(e.contract.pit);
                    setTaxDeductible(e.contract.taxDeductible);
                  }
                }
              />
            </div>
            {edit && bill &&
              <div>
                <InputWithAlert
                  label={t("bills:bills_id")}
                  value={billId}
                  onChange={(e) => setBillId(e.target.value)}
                  error={!billId}
                  errorMessage={t('empty_data_invalid')}
                  inputClassName={'h-12  mb-4 bg-white'}
                />
              </div>
            }
            {contract && (
              <>
                {clientBankAccounts && (
                  <SelectWithAlert
                    label={t('clients:bank_account_client')}
                    placeholder={t('clients:bank_account_client')}
                    options={clientBankAccounts ? clientBankAccounts.map(o => ({ value: o.account, label: `${o.account} ${o.currency}` })) : []}
                    onChangeValue={(event) => setBankAccount(event)}
                    error={!bankAccount}
                    errorMessage={t("bills:alert_empty_bank_account_message")}
                    value={bankAccount}
                  />)}
                {user.data.roles !== 'CLIENT' && contract && (<SelectWithAlert
                  label={t("bills:bills_signatory")}
                  options={Signatories[contract.representativeCompany]}
                  value={signatory}
                  onChangeValue={setSignatory}
                  inputClassName={'h-12 mt-2 mb-4 bg-white'}
                />)
                }
                {
                  user.data.roles !== "CLIENT" && !!specificationStages && specifications.length > 0 && (
                    <div className="w-full mb-4 h-">
                      <Link to={`/specification/edit/${specification}`} > Edit specification</Link>
                    </div>)
                }
                {
                  !!specificationStages && specifications.length > 0 && (
                    <SelectWithAlert
                      label={t("specifications:spec_stage")}
                      placeholder={t("specifications:spec_stage")}
                      options={specificationStages ? specificationStages.map(o => ({ value: o.id, label: `${o.bills.length > 0 ? '✔️' : '❌'} ${o.description} ${o.id}`, specificationItem: o })) : []}
                      onChangeValue={(event) => {
                        const selectedSpecificaiton = specificationStages.find(e => (e.id == event))
                        const { grossAmount: localGrossAmount, endDate: localEndDate } = selectedSpecificaiton
                        if (localGrossAmount) setGrossAmount(localGrossAmount);
                        if (localEndDate) setConclusionDate(new Date(localEndDate));
                        setSpecificaitonStage(event)
                      }
                      }
                      value={specificationStage}
                    />)
                }
                <div className='font-medium mb-4'>
                  Comment
                </div>
                <Textarea
                  placeholder={"Comment"}
                  value={comment ?? ''}
                  onChange={(e) => (setComment(e.target.value))}
                  className="h-20 mb-4 bg-white"
                />
                <div className="w-full">
                  <DatePicker
                    label={t("contracts:conclusion_date")}
                    date={conclusionDate}
                    defaultMonth={conclusionDate}
                    setDate={
                      (date) => {
                        if (!date) return
                        setConclusionDate(date)
                      }
                    }
                    inputClassName={'h-12 mt-2 mb-4 bg-white'}
                  />
                </div>
                <div className="w-full">
                  <SelectWithAlert
                    label={t('status')}
                    options={BILL_STATUS(t)}
                    value={status}
                    onChangeValue={setStatus}
                  />
                </div>

                {status !== "Pending" &&
                  <div className="w-full">
                    <DatePicker
                      label={t("contracts:accepted")}
                      date={acceptedDate}
                      defaultMonth={acceptedDate}
                      setDate={
                        (date) => {
                          if (!date) return
                          setAcceptedDate(date)
                        }
                      }
                      error={status === 'Accepted' && !acceptedDate}
                      errorMessage={t('empty_data_invalid')}
                      inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />
                  </div>
                }
                <div className='w-full'>
                  <SelectWithAlert
                    label={t("common:type") + "*"}
                    options={CONTRACT_TYPES(t)}
                    disabled={true}
                    value={contractType}
                    onChangeValue={setContractType}
                  />
                </div>

                <div className='w-full'>
                  <SelectWithAlert
                    label={t("contracts:tax_deductible")}
                    options={CONTRACT_TAX_DEDUCTIBLES(t)}
                    disabled={true}
                    value={taxDebuctible ?? ''}
                  />
                </div>
                <div className='w-full'>
                  <SelectWithAlert
                    label={"PIT"}
                    options={CONTRACT_PITS(t)}
                    value={pit ?? ''}
                    error={!isPit12Avalible && pit !== CONTRACT_PIT.THIRTY_TWO_PERCENT}
                    errorMessage={"Pit 32%!"}
                    onChangeValue={setPit}
                  />
                </div>
                <div className='w-full'>
                  <InputWithAlert
                    label={t("contracts:gross_amout") + " PLN "}
                    value={grossAmount}
                    onChange={(e) => setGrossAmount(parseNumber(e.target.value))}
                    errorMessage={t("bills:alert_state_message")}
                    error={contract.contractType != CONTRACT_TYPE.UMOWA_ZLECENIE ?
                      contract.actualGrossAmount - billSum - grossAmount < -0.01 : false}
                    inputClassName={'h-12  mb-4 bg-white'}
                  />
                </div>


                <div>
                  <SelectWithAlert
                    label={t("invoices:currency")}
                    options={SUPPORTED_CURRENCIES}
                    onChangeValue={setCurrency}
                    value={currency}
                    inputClassName={'h-12 bg-white mb-4'}
                  />
                  {currency && currency !== 'PLN' && (
                    <DatePicker
                      label={t("invoices:payment_currency_date")}
                      date={currencyDate}
                      defaultMonth={currencyDate}
                      disabled={{ after: new Date() }}
                      error={currency && currency !== 'PLN' && !currencyDate}
                      errorMessage={t("empty_data_invalid")}
                      setDate={(date) => {
                        if (!date) return
                        setCurrencyDate(date)
                      }}
                      inputClassName={'h-12 mb-4 text-base'}
                    />)}
                  {currency && currency !== 'PLN' && (
                    <InputWithAlert
                      label={t('invoices:currency_rate_PLN')}
                      value={currencyRate}
                      onChange={(e) => {}}
                      disabled
                      inputClassName={'h-12 mt-2 mb-4 bg-white'}
                    />)}
                </div>
                {contractType === CONTRACT_TYPE.UMOWA_ZLECENIE && (
                  <>
                    <div className="w-full text-xl font-medium py-6 ">
                      Emyrentalne rentowe
                      <Switch className={"ml-3"} checked={emerytalneRentowe} onCheckedChange={setEmerytanleRentowe} />
                    </div>
                    <div className="w-full text-xl font-medium py-6 ">
                      Chorobowe
                      <Switch className={"ml-3"} checked={chorobowe} onCheckedChange={setChorobowe} />
                    </div>
                    <div className="w-full text-xl font-medium py-6 ">
                      Zdrowotne
                      <Switch className={"ml-3"} checked={zdrowotna} onCheckedChange={setZdrowotne} />
                    </div>
                  </>)}
              </>)}
          </div>
          <div className="w-5/12">
            <div className='w-full'>
              {
                contract && (
                  <InputWithAlert
                    label={t("client")}
                    value={`${contract.client.firstName} ${contract.client.lastName}`}
                    disabled={true}
                    inputClassName={'h-12  mb-4 bg-white'}
                  />
                )
              }
            </div>
            <div className='w-full'>
              {
                bills && bills.data && (
                  <InputWithAlert
                    label={t("bills:client's_income")}
                    value={bills.data.bills.reduce((a, b) => a + b.income, 0).toFixed(2)}
                    disabled={true}
                    inputClassName={'h-12  mb-4 bg-white'}
                  />
                )
              }
            </div>
            <div className='w-full'>
              {
                contract && (
                  <InputWithAlert
                    label={t("contracts:gross_amout")}
                    value={contract.actualGrossAmount.toFixed(2)}
                    disabled={true}
                    error={contract.contractType != CONTRACT_TYPE.UMOWA_ZLECENIE ?
                      contract.actualGrossAmount - billSum - grossAmount < -0.01 : false}
                    errorMessage={errorMessage}
                    inputClassName={'h-12  mb-4 bg-white'}
                  />
                )
              }
            </div>
            <div className='w-full'>
              {
                contract && (
                  <InputWithAlert
                    label={t('bills:paid')}
                    value={billSum}
                    disabled={true}
                    error={contract.contractType != CONTRACT_TYPE.UMOWA_ZLECENIE ?
                      contract.actualGrossAmount - billSum - grossAmount < -0.01 : false}
                    errorMessage={errorMessage}
                    inputClassName={'h-12  mb-4 bg-white'}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>)
}
